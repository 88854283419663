import { useState, React } from "react";
import axios from "axios";
export default function AddPlanForm({ closeModal, setRenderToggle }) {
    const [name, setName] = useState("");
    const onNameChange = (e) => setName(e.target.value);
    const [no_of_classes, setNoOfClasses] = useState("");
    const onNoOfClassesChange = (e) => setNoOfClasses(e.target.value);
    function handleClick(e) {
        e.stopPropagation();
        const headers = {
            "Content-Type": "text/plain",
        };

        var postData = { name, no_of_classes };
        axios
            .post("https://api.bollywoodfunknyc.com/plans.php", postData, {
                headers,
            })
            .then((res) => {
                setRenderToggle((renderToggle) => !renderToggle);
            });
        closeModal();
    }
    return (
        <form>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Subscription Name
            </label>
            <div className="mt-1">
                <input
                    type="text"
                    name="name"
                    id="name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="eg. 5 Class Package"
                    onChange={onNameChange}
                    required
                />
            </div>

            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 pt-6">
                Number of classes
            </label>
            <div className="mt-1">
                <input
                    type="number"
                    name="no_of_classes"
                    id="no_of_classes"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="eg. 10"
                    onChange={onNoOfClassesChange}
                    required
                />
            </div>

            <div className="mt-12 text-right">
                <button
                    type="button"
                    className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium text-gray-900 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleClick}
                >
                    Save
                </button>
            </div>
        </form>
    );
}
