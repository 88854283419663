import { useState, useEffect, React, Fragment } from "react";
import Loader from "../Loader";
import { CubeIcon, DotsVerticalIcon, PlusIcon, UsersIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import DeletePlanModal from "./DeletePlanModal";
import AddModal from "./AddModal";
import AddPlanForm from "./AddPlanForm";
import AddInstructorForm from "./AddInstructorForm";
import ArchiveInstructorModal from "./ArchiveInstructorModal";

const secondaryNavigation = [
    { name: "Plans", href: "#", icon: CubeIcon, current: true },
    { name: "Instructors", href: "#", icon: UsersIcon, current: false },
];

export default function PlansView() {
    const [plans, setPlans] = useState(null);
    const [instructors, setInstructors] = useState(null);
    const [currentView, setCurrentView] = useState("Plans");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [renderToggle, setRenderToggle] = useState(false);

    const [planToDelete, setPlanToDelete] = useState(0);
    const [instructorToArchive, setInstructorToArchive] = useState(0);

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deletePlanModalIsOpen, setDeletePlanModalIsOpen] = useState(false);
    const [archiveInstructorModalIsOpen, setArchiveInstructorModalIsOpen] = useState(false);

    useEffect(() => {
        async function getPlans() {
            try {
                const response = await fetch(`https://api.bollywoodfunknyc.com/plans.php`);
                if (!response.ok) {
                    setError(response.status);
                    throw new Error(`Error! status: ${error}`);
                }
                setError(null);
                const result = await response.json();
                if (result) {
                    setLoading(false);
                }
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        async function getInstructors() {
            try {
                const response = await fetch(`https://api.bollywoodfunknyc.com/instructors.php`);
                if (!response.ok) {
                    setError(response.status);
                    throw new Error(`Error! status: ${error}`);
                }
                setError(null);
                const result = await response.json();
                if (result) {
                    setLoading(false);
                }
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        getPlans().then((plans) => {
            setPlans(plans);
        });
        getInstructors().then((instructors) => {
            setInstructors(instructors);
        });
    }, [error, renderToggle]);

    return (
        <>
            {loading && <Loader />}
            {error && <div>{`There is a problem fetching the post data - ${error}`}</div>}
            {currentView === "Plans" && (
                <AddModal
                    form={<AddPlanForm closeModal={() => setAddModalIsOpen(false)} setRenderToggle={setRenderToggle} />}
                    title="New Plan"
                    isOpen={addModalIsOpen}
                    setIsOpen={setAddModalIsOpen}
                />
            )}
            {currentView === "Instructors" && (
                <AddModal
                    form={
                        <AddInstructorForm
                            closeModal={() => setAddModalIsOpen(false)}
                            setRenderToggle={setRenderToggle}
                        />
                    }
                    title="New Instructor"
                    isOpen={addModalIsOpen}
                    setIsOpen={setAddModalIsOpen}
                />
            )}
            <DeletePlanModal
                isOpen={deletePlanModalIsOpen}
                setIsOpen={setDeletePlanModalIsOpen}
                plan_id={planToDelete}
                setRenderToggle={setRenderToggle}
            />
            <ArchiveInstructorModal
                isOpen={archiveInstructorModalIsOpen}
                setIsOpen={setArchiveInstructorModalIsOpen}
                instructor_id={instructorToArchive}
                setRenderToggle={setRenderToggle}
            />
            {currentView === "Plans" && (
                <button
                    type="button"
                    onClick={() => {
                        setAddModalIsOpen(true);
                    }}
                    className="inline-flex fixed bottom-10 right-10 shadow-lg px-5 py-3 border border-transparent text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                    Add Plan
                </button>
            )}
            {currentView === "Instructors" && (
                <button
                    type="button"
                    onClick={() => {
                        setAddModalIsOpen(true);
                    }}
                    className="inline-flex fixed bottom-10 right-10 shadow-lg px-5 py-3 border border-transparent text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                    Add Instructor
                </button>
            )}
            <div className="lg:flex lg:gap-x-16 lg:px-8">
                <h1 className="sr-only">General Settings</h1>
                <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                    <nav className="flex-none px-4 sm:px-6 lg:px-0">
                        <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                            {secondaryNavigation.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        onClick={() => setCurrentView(item.name)}
                                        className={classNames(
                                            currentView === item.name
                                                ? "bg-gray-50 text-indigo-600"
                                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                            "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm/6 font-semibold"
                                        )}
                                    >
                                        {item.icon && (
                                            <item.icon
                                                aria-hidden="true"
                                                className={classNames(
                                                    currentView === item.name
                                                        ? "text-indigo-600"
                                                        : "text-gray-400 group-hover:text-indigo-600",
                                                    "h-6 w-6 shrink-0"
                                                )}
                                            />
                                        )}
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>
                <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                    {currentView === "Plans" &&
                        plans &&
                        plans.map((plan, index) => (
                            <div className="relative p-4 bg-white border-b" key={index}>
                                <div className="ml-4">
                                    <h3 className="text-md font-medium">{plan.name}</h3>
                                    <div className="mt-1 text-sm">
                                        <p>Number of classes: {plan.no_of_classes}</p>
                                    </div>
                                </div>
                                <Menu as="div" className="flex-shrink-0 pr-2 absolute right-4 top-6">
                                    <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="z-10 mx-3 origin-top-right absolute right-2 top-8 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames(
                                                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                "block px-4 py-2 text-sm w-full text-left"
                                                            )}
                                                            onClick={() => {
                                                                setPlanToDelete(plan.id);
                                                                setDeletePlanModalIsOpen(true);
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        ))}
                    {currentView === "Instructors" &&
                        instructors &&
                        instructors.map((instructor, index) => (
                            <div className="relative p-4 bg-white border-b" key={index}>
                                <div className="ml-4">
                                    <h3 className="text-md font-medium">{instructor.full_name}</h3>
                                </div>
                                <Menu as="div" className="flex-shrink-0 pr-2 absolute right-4 top-3">
                                    <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="z-10 mx-3 origin-top-right absolute right-2 top-8 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames(
                                                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                "block px-4 py-2 text-sm w-full text-left"
                                                            )}
                                                            onClick={() => {
                                                                setInstructorToArchive(instructor.id);
                                                                setArchiveInstructorModalIsOpen(true);
                                                            }}
                                                        >
                                                            Archive
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        ))}
                </main>
            </div>
        </>
    );
}
